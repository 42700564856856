import React from 'react'
import './follows.scss'
import bluetop from '../../images/top.png';
import bluebottom from '../../images/bottom.png';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Follows = () => {
  return (
    <section className="follows">
        <img src={bluetop} className='top-img' alt="bluetop" />
        <div className='center'>
            <div className="flex">
                <h3>Kövess minket</h3>
                <div className="social">
                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><FaFacebookF /></a>
                    <a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                </div>
            </div>
        </div>
        <img src={bluebottom} className='bottom-img' alt="bluebottom" />
    </section>
  )
}

export default Follows