import React from 'react'
import './portfolio.scss'
import { portfolio } from '../../data'

const Portfolio = () => {
  return (
    <section id='portfolio' className="portfolio">
      <div className="container">
        <h3>Portfólio</h3>
        <div className="portfolio-cards">
          {portfolio.map((portfolio, index) => (
            <a key={index} href={portfolio.path} target='_blank' rel="noreferrer" className="portfolio-card">
              <img src={portfolio.img} alt="portfolio-image" />
              <h4>{portfolio.text}</h4>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Portfolio