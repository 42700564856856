import Header from '../src/components/header/header'
import Hero from '../src/components/hero/hero'
import Profession from '../src/components/profession/profession'
import Services from '../src/components/services/services'
import Choose from '../src/components/choose/choose'
import Portfolio from '../src/components/portfolio/portfolio'
import Contact from '../src/components/contact/contact'
import Footer from '../src/components/footer/footer'
import Follows from './components/follows/follows'
import About from './components/about/about'

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Profession />
      <Services />
      <div className='background'>
        <About />
        <Choose />
      </div>
      <Portfolio />
      <Contact />
      <Follows />
      <Footer />
    </div>
  );
}

export default App;
