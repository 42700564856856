import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <footer>
      <p>@2024 Net4Yard minden jog fenntartva</p>
    </footer>
  )
}

export default Footer