import React, { useState, useEffect } from 'react';
import './profession.scss';

const cards = [
  { title: "Állandó kapcsolattartás", textBoxTitle: "Megbízhatóság", content: "Csapatunk a minőség mellett törekszik a gyorsaságra, megbízhatóságra. Önnek nem kell aggódnia afelől, hogy weboldala profi kezekben van." },
  { title: "Arculatterv készítés", textBoxTitle: "Arculattervek", content: "Kezdő vállalkozó? Vállalkozásának arculatát is megtervezzük. Logó, névjegykártya és egyéb grafikai elemeket készítünk, hogy igazán kitűnjön a tömegből." },
  { title: "Gyors, megbízható munka", textBoxTitle: "Kapcsolattartás", content: "Vállalkozásunkkal folyamatosan kapcsolatban maradhat a projekt során, így mindig tudja, hogy hol tartunk." },
  { title: "Állandó kapcsolattartás", textBoxTitle: "Rólunk", content: "Csapatunk fiatalos és rugalmas. Minden egyes projektet, ügyfelet egyedi módon kezelünk." },
  { title: "Fiatalos, rugalmas csapat", textBoxTitle: "Marketing", content: "Ha készen van weboldala, attól még nem biztos, hogy sikeres lesz a vállalkozása. Fontos, hogy a weboldalát megfelelően reklámozza. Ebben is segítünk." }
];

const Profession = () => {
  const [activeIndex, setActiveIndex] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  const getCardStyle = (index) => {
    const relativeIndex = (index - activeIndex + cards.length) % cards.length;
    const position = Math.abs(relativeIndex - 2);
    return {
      zIndex: position === 0 ? cards.length : cards.length - position,
      opacity: position === 0 ? 1 : 0.5,
      transform: `translateY(${(relativeIndex - 2) * 20}px) scale(${position === 0 ? 1.1 : 1})`,
    };
  };

  return (
    <section id='profession' className="profession">
      <div className="container width">
        <div className="row">
          <div className="col-50">
            <div className="middle">
              <div className="profession-text-box">
                <h2>{cards[activeIndex].textBoxTitle}</h2>
                <p>{cards[activeIndex].content}</p>
              </div>
            </div>
          </div>
          <div className="col-50 cards-container">
            {cards.map((card, index) => (
              <div
                key={index}
                className={`card ${index === activeIndex ? 'active' : ''}`}
                style={{ 
                  backgroundColor: `hsl(${index * 72}, 70%, 70%)`,
                  ...getCardStyle(index)
                }}
                onClick={() => handleCardClick(index)}
              >
                <h3>{card.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profession;
