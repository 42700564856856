import React from 'react'
import './services.scss'
import { services } from '../../data'

const Services = () => {
  return (
    <section id='services' className="services">
      <div className="container">
        <h3>Szolgáltatásaink</h3>
        <div className="row mt-32 gap-15">
          {services.map((serv, index) => (
            <div key={index} className="col-33">
              <div className="services-card">
                <h4>{serv.title}</h4>
                <p>{serv.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Services