import React from 'react'
import './hero.scss'

const Hero = () => {
  return (
    <section id='hero' className="hero">
      <div className="welcome">
        <h1>Net4Yard</h1>
        <p>Weboldalak és szoftverek fejlesztése, amelyekkel hatékonyan növelheti online jelenlétét.</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-33">
            <div className="left-panel">
              <h2>Modern weboldalak <br /><span className="underline">fejlesztése</span></h2>
              <p>Egyedi weboldalakat fejlesztünk, az Ön igényeire szabva.	A vállalkozása ki szeretne tűnni a tömegből, mi ebben segítünk!</p>
            </div>
          </div>
          <div className="col-66">
            <div className="right-panel">
              <h2>Mi segíthetünk Önnek elhelyezkedni az interneten!</h2>
              <p>Egy weboldal kritikus része a vállalkozásának, ha az internetről is szeretne potenciális vásárlókat. Mindenki szeretne egy weboldalt, az nem mindegy, hogy kitől és milyen minőségben érkezik Önhöz.</p>
              <p>Önnel állandó kommunikációt tartva fejlesztjük honlapját, figyelve igényeire. Gyorsak és megbízhatóak vagyunk, ügyelve a minőségre.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero