import React, { useState } from 'react';
import './contact.scss';
import { FaChevronRight } from "react-icons/fa";
import { questions } from '../../data';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:3001/send-email', formData);
            toast.success('Email sent successfully!', {
                position: "bottom-right"
            });
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error sending email', error);
            toast.error('Failed to send email.', {
                position: "bottom-right"
            });
        }
    };

    return (
        <section id='contact' className="contact">
            <div className="container">
                <h3 className='contact-title'>Kapcsolat</h3>
                <div className="max-width">
                    <p className='paragraph-title'>Készen áll arra, hogy új szintre emelje digitális jelenlétét? Lépjen kapcsolatba velünk még ma, és kezdjük el közösen a jövő építését!</p>
                </div>
                <div className="row">
                    <div className="col-66">
                        <div className='questions'>
                            {questions.map((question, index) => (
                                <div key={index}>
                                    <div className={`flex question__title ${activeIndex === index ? 'active__arrow' : ''}`} onClick={() => handleClick(index)}>
                                        <FaChevronRight />
                                        <h4>{question.title}</h4>
                                    </div>
                                    <p className={activeIndex === index ? 'active__text' : 'text'}>{question.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-33">
                        <form className="personal__card" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder='Név'
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder='E-mail'
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="subject"
                                placeholder='Tárgy'
                                value={formData.subject}
                                onChange={handleChange}
                            />
                            <textarea
                                name="message"
                                cols="30"
                                rows="10"
                                placeholder='Üzenet'
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                            <button type="submit">Küldés</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
};

export default Contact;
