import React, { useState, useEffect, useRef, useMemo } from 'react';
import './header.scss';
import { RxHamburgerMenu } from "react-icons/rx";
import logo from '../../images/logo.png';
import { FaHome, FaTasks, FaShareSquare, FaEnvelope, FaUsers } from "react-icons/fa";
import { FaGear, FaPencil } from "react-icons/fa6";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [activeNav, setActiveNav] = useState('#');
  const headerRef = useRef(null);

  const sections = useMemo(() => ['#hero', '#profession', '#services', '#about', '#choose', '#portfolio', '#contact'], []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      let currentSection = '#';
      sections.forEach(section => {
        const element = document.querySelector(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            currentSection = section;
          }
        }
      });

      setActiveNav(currentSection);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [headerRef]);

  const handleHeaderClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLinkClick = (section) => {
    setActiveNav(section);
    setDropdownVisible(false);
  };

  return (
    <header ref={headerRef}>
      <div className="container">
        <div className='center'>
          <div className={`header-container ${scrolled ? 'scrolled' : ''}`} onClick={handleHeaderClick}>
            <div className="flex">
              <RxHamburgerMenu />
              Net4Yard
            </div>
          </div>
          <div className='absolute'>
            <div className={`header-dropdown ${dropdownVisible ? 'show' : ''} ${scrolled ? 'small' : ''}`}>
              <div className='dropdown-top'>
                <img src={logo} className='logo' alt="logo" />
              </div>
              <div className="dropdown-bottom">
                <ul>
                  <li><a href='#hero' onClick={() => handleLinkClick('#hero')} className={activeNav === '#hero' ? 'active' : ''}><FaHome />Főképernyő</a></li>
                  <li><a href='#profession' onClick={() => handleLinkClick('#profession')} className={activeNav === '#profession' ? 'active' : ''}><FaGear />Mivel foglalkozunk?</a></li>
                  <li><a href='#services' onClick={() => handleLinkClick('#services')} className={activeNav === '#services' ? 'active' : ''}><FaTasks />Szolgáltatásaink</a></li>
                  <li><a href='#about' onClick={() => handleLinkClick('#about')} className={activeNav === '#about' ? 'active' : ''}><FaUsers />Rólunk</a></li>
                  <li><a href='#choose' onClick={() => handleLinkClick('#choose')} className={activeNav === '#choose' ? 'active' : ''}><FaShareSquare />Miért válasszon minket?</a></li>
                  <li><a href='#portfolio' onClick={() => handleLinkClick('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><FaPencil />Portfólio</a></li>
                  <li><a href='#contact' onClick={() => handleLinkClick('#contact')} className={activeNav === '#contact' ? 'active' : ''}><FaEnvelope />Kapcsolat</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
