import React from 'react'
import './about.scss'
import { technologies } from '../../data'

const About = () => {
    return (
        <section id='about' className="about">
            <div className="container">
                <div className="company-description">
                    <h3>Rólunk</h3>
                    <p>Cégünk vezető szerepet tölt be a szoftverfejlesztés terén. Innovatív megoldásainkkal segítünk ügyfeleinknek elérni üzleti céljaikat.</p>
                </div>
                <div className="technologies">
                    <h4>Technológiák</h4>
                    <div className="technology-list">
                        {technologies.map((tech, index) => (
                            <div key={index} className="technology-item">
                                <img src={tech.img} alt={tech.alt} />
                                <p>{tech.alt}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About